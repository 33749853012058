import axios from 'axios';
import React from 'react'
import { useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { permission } from 'helper/permission';
import collect from 'collect.js';

import Select from 'react-select';
import AttributeList from '../Attribute/AttributeList';

export default function ProductAttribute() {

    const {id} = useParams();
    const { token, user } = useSelector(state => state.auth);
    const [options, setOptions] = React.useState([]);
    const [selectedOption, setSelectedOption] = React.useState('');
    const [items, setItems] = React.useState([0]);
    const [attributes, setAttributes] = React.useState([]);
    const [crossAttributes, setCrossAttributes] = React.useState([]);
    const [crossData, setCrossData] = React.useState([]);
    const [formValue, setFormValue] = React.useState([]);
    const [variations, setVariations] = React.useState([]);
    const [galleryItems, setGalleryItems] = React.useState([]);

    let history = useHistory();

    React.useEffect(() => {
        if(user?.permissions)
        {
            if(!(permission(user.permissions, 'products_manage_product', 'update') || permission(user.permissions, 'products_manage_product', 'create')) && (user.user_type_id != 1))
                history.push('/admin');
        }
    }, [user])


    const fetchOptions = () => {
        axios.get(`/attributes`, {
            headers: {
                Accept: 'application/json',
                Authorization: token
            }
        }).then(response => {
            // console.log(response);
            setOptions([]);

            response?.data?.map((item, index) => {
                // console.log(item.imageable);
                setOptions(prevState => [...prevState, {label: item.name, value: item.id, imageable: item.imageable, values: item.values}])           
            })
            
            
        }).catch(error => {
            console.log(error.response);
        })
    }

    React.useEffect(() => {
        if(token != '')
        {
            fetchOptions();
            getVariation();
            getGallery();
            availableAttribute();
        }
    }, [id, token]);

    const availableAttribute = () => {
        axios.get(`products/available/attributes/${id}`, {
            headers: {
                Accept: 'application/json',
                Authorization: token
            }
        }).then(response => {
            console.log(response.data);
        }).catch(error => {
            console.log(error.response);
        })
    }

    const getGallery = () => {
        axios.get(`products/attributes/gallery/${id}`, {
            headers: {
                Accept: 'application/json',
                Authorization: token
            }
        }).then(response => {
            // console.log(response);
            setGalleryItems(response.data);
        }).catch(error => {
            console.log(error.response);
        })
    }

    const getVariation = () => {
        axios.get(`products/${id}/add-attribute`, {
            headers: {
                Accept: 'application/json',
                Authorization: token
            }
        }).then(response => {
            console.log(response);
            setVariations(response.data);
        }).catch(error => {
            console.log(error.response);
        })
    }

    const handleOptionSelect = (option, itemIndex) => {
        



        const getAttributes = options.findIndex(item => item === option);

        // console.log(options[getAttributes]);

        const itemsOf = [];

        options[getAttributes]?.values.map(item => {
            itemsOf.push({label: item.value, value: item.value});
            // console.log(item);
        })

        const attributeItem = {
            [itemIndex]: itemsOf
        }

        console.log(attributeItem);

        setAttributes(attributeItem)

    }

    const handleAddMoreItem = () => {

        setItems([...items, items.length]);
    }

    const handleAttributeChange = (options) => {

        // console.log(options);
        const crossItem = crossAttributes.filter(item => item.itemIndex != options.itemIndex)

        // console.log(crossAttributes);

        const data = options;

        crossItem.push(data);

        setCrossAttributes(crossItem);

        const crossData = [];

        let rowData = [];

        crossItem.map(item => {
            rowData = [];
            item.options.map(data => {
                rowData.push(data.value);
            })
            crossData.push(rowData);
        })

        const collection = collect(crossData[0]);

        const crossTwo = [];

        crossData.map((item, index) => {
            if(index != 0)
                crossTwo.push(item);
        })

        const joined = collection.crossJoin(...crossTwo).all();

        setCrossData(joined);
    }

    const handleVariationSubmit = e => {
        e.preventDefault();
        // console.log(formValue);

        let formData = new FormData();

        formValue.map((item, index) => {
            formData.append('stock[]', item.stock ? item.stock : null);
            formData.append('variable[]', item.variable ? item.variable : null);
        })

        axios.post(`/products/${id}/cross`, formData, {
            headers: {
                "Accept": 'application/json',
                "Authorization": token,
            }
        }).then(response => {
            // console.log(response);
            getVariation();
            getGallery();
        }).catch(error => {
            console.log(error.response);
        })
    }

    const changingStock = (e, index) => {
        let items = [...formValue];
        let item = {...items[index]};
        item.stock = e.target.value;
        items[index] = item;
        setFormValue(items);
    }

    const updateStock = (index) => {
        let items = [...variations];
        let item = {...items[index]};
        let stock = item.stock;

        axios.post(`products/group/${item.id}/update`, {
            stock: stock
        }, {
            headers: {
                Accept: 'application/json',
                Authorization: token
            }
        }).then(response => {
            console.log(response);
        }).catch(error => {
            console.log(error.response);
        })
    }

    
    React.useEffect(() => {
        // console.log(crossData);
        // console.log(formValue);

        setFormValue([]);
        if(crossData.length > 0)
        {
            crossData.map((item, index) => {
                const newData = {itemIndex: index, stock: 0, variable: item}
                setFormValue(prevState => [...prevState, newData])
            })
        }

    }, [crossData])

    // React.useEffect(() => {
        
    //     console.log(formValue);
    // }, [formValue]);

    const changingUpdateStock = (e, index) => {
        let items = [...variations];
        let item = {...items[index]};
        item.stock = e.target.value;
        items[index] = item;
        setVariations(items);
    }

    const removeGalleryImage = (id) => {
        axios.delete(`products/attributes/gallery/${id}`, {
            headers: {
                Accept: 'application/json',
                Authorization: token
            }
        }).then(response => {
            getGallery();
        }).catch(error => {
            console.log(error.response);
        })
    }

    const uploadValueImage = (e, value) => {
        // console.log(value);
        let formData = new FormData();

        formData.append('value', value);
        formData.append('thumbnail', e.target.files[0]);

        axios.post(`/products/attributes/gallery/${id}`, formData, {
            headers: {
                Accept: 'application/json',
                Authorization: token
            }
        }).then(response => {
            getGallery();
        }).catch(error => {
            console.log(error.response);
        })
    }
    return (
        <>
            <div className="px-8 mt-8 mb-8">
                <div className="page-heading">
                    <h1 className="pageHeading">Add Attribute To Product</h1>
                    <div className="flex">
                        <Link to="/admin/all-products" className="button button-success px-2">Back To Products</Link>
                    </div>
                </div>
                
                <div className="w-full flex wrap -mx-1">
                    {variations.length > 0 ? (
                        <>
                        <div className="w-1/2 mx-1">
                            <div className="w-full mt-4">
                                <div className="card">
                                    <div className="card-header">
                                        <div>
                                            <h4 className="pageHeading">Variations</h4>
                                        </div>
                                    </div>
                                    <div className="card-body py-4 w-full">
                                        {variations.map((item, index) => (
                                            <div className='w-full mt-2'>
                                                <label className='w-full'>
                                                    {item?.attributes?.map(data => (
                                                        <span>{data?.value}/</span>
                                                    ))}
                                                </label>
                                                <div className='w-full mt-2'>
                                                    <input type="number" className='w-1/2' placeholder='set quantity' value={item.stock} onChange={e => changingUpdateStock(e, index)} onBlur={() =>updateStock(index)} required />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* <div className='w-full mt-4 mb-4'>
                                <div className="h-screen mx-1">
                                    <div className="card">
                                        <div className="border-b">
                                            <div className="card-header">
                                                <div>
                                                    <h4 className="pageHeading">Add Option</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body overflow-auto" style={{minHeight: '350px'}}>
                                                {items.map((number, index) => (
                                                    <>
                                                    <div className="w-full flex my-2">
                                                        <div className='w-1/3'>
                                                            <div className="w-full">
                                                                <label>Select Option</label>
                                                            </div>
                                                            <div className="w-full mt-2">
                                                                <Select className="top-auto bottom-full" onChange={option => [handleOptionSelect(option, index)]} maxMenuHeight={250} options={options} />
                                                            </div>
                                                        </div>
                                                        <div className='w-2/3 mx-2'>
                                                            <div className="w-full">
                                                                <label>Select Attribute</label>
                                                            </div>
                                                            <div className="w-full mt-2">
                                                                <Select className="top-auto bottom-full" onChange={option => [handleAttributeChange({itemIndex: index, options: option})]} maxMenuHeight={250} options={attributes[index]} isMulti={true} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                ))}

                                                
                                                <div className="w-full flex my-2">
                                                    {(items.length < options.length) ? (
                                                        <button className='bg-blue-600 text-white px-2 rounded mt-2' onClick={handleAddMoreItem}>Add More</button>
                                                    ) : ''}
                                                </div>
                                                
                                            
                                        </div>
                                    </div>

                                </div>
                            </div> */}
                        </div>
                        <div className="w-1/2 mx-1">
                            <div className="w-full mt-4">
                                <div className="card">
                                    <div className="card-header">
                                        <div>
                                            <h4 className="pageHeading">Gallery Image</h4>
                                        </div>
                                    </div>
                                    <div className="card-body py-4 w-full">
                                        
                                            {galleryItems.map((item, index) => (
                                                <div className='w-full mb-2' key={index}>
                                                    <div className='font-bold mb-4 mt-6'>{item.value}</div>
                                                    <div className='w-full flex flex-wrap'>
                                                        {item?.thumbnails?.map(thumbnail => (
                                                            <div className='w-18 h-18 border mr-4'>
                                                                <button className='text-red-600 cursor-pointer absolute w-6 h-6 bg-white border flex justify-center items-center rounded-full -mt-2 -ml-2' onClick={() => removeGalleryImage(thumbnail.id)}>
                                                                    <i class="fa fa-times"></i>
                                                                </button>
                                                                <img className='h-full w-auto' src={thumbnail.thumbnail} />
                                                            </div>
                                                        ))}
                                                        <div className='w-18 h-18 border flex justify-center items-center'>
                                                            <label htmlFor={`selectImage${index}`} className='text-3xl cursor-pointer'>+</label>
                                                            <input type='file' id={`selectImage${index}`} onChange={(e) => uploadValueImage(e, item.value)} accept='image/*' className='hidden' />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    ) : (
                    <>

                    <div className="w-1/2 h-screen mx-1">
                        <div className="card">
                            <div className="border-b">
                                <div className="card-header">
                                    <div>
                                        <h4 className="pageHeading">Add Option</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body overflow-auto" style={{minHeight: '350px'}}>
                                    {items.map((number, index) => (
                                        <>
                                        <div className="w-full flex my-2">
                                            <div className='w-1/3'>
                                                <div className="w-full">
                                                    <label>Select Option</label>
                                                </div>
                                                <div className="w-full mt-2">
                                                    <Select className="top-auto bottom-full" onChange={option => [handleOptionSelect(option, index)]} maxMenuHeight={250} options={options} />
                                                </div>
                                            </div>
                                            <div className='w-2/3 mx-2'>
                                                <div className="w-full">
                                                    <label>Select Attribute</label>
                                                </div>
                                                <div className="w-full mt-2">
                                                    <Select className="top-auto bottom-full" onChange={option => [handleAttributeChange({itemIndex: index, options: option})]} maxMenuHeight={250} options={attributes[index]} isMulti={true} />
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    ))}

                                    
                                    <div className="w-full flex my-2">
                                        {(items.length < options.length) ? (
                                            <button className='bg-blue-600 text-white px-2 rounded mt-2' onClick={handleAddMoreItem}>Add More</button>
                                        ) : ''}
                                    </div>
                                    
                                
                            </div>
                        </div>

                    </div>
                    <div className="w-1/2 mx-1">
                        <div className="w-full mt-4">
                            <div className="card">
                                <div className="card-header">
                                    <div>
                                        <h4 className="pageHeading">Variations</h4>
                                    </div>
                                </div>
                                <form onSubmit={(e) => handleVariationSubmit(e)}>
                                <div className="card-body py-4 w-full">
                                    {crossData.map((item, index) => (
                                        <div className='w-full mt-2'>
                                            <label className='w-full'>
                                                {item.map(data => (
                                                    <span>{data}/</span>
                                                ))}
                                            </label>
                                            <div className='w-full mt-2'>
                                                
                                                <input type="number" className='w-1/2' placeholder='set stock' value={item.stock} onChange={e => changingStock(e, index)} required />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                
                                {crossData.length > 0 ? (
                                    <>
                                        <div className='w-full flex justify-end'>
                                            <button className='bg-blue-600 text-white py-2 px-4 rounded'>Submit</button>
                                        </div>
                                    </>
                                ) : ''}
                                </form>
                            </div>
                        </div>
                    </div>
                    
                    </>
                    
                    )}
                </div>
            </div>
        </>
    )
}
