import axios, { AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import { notifySuccess, Toast } from "helper/notify";
import toast from "react-hot-toast";
import Couriers from "../Courier/Couriers";
import { permission } from "helper/permission";
import { Link } from "react-router-dom";
import Moment from "react-moment";

export default function UpdateOrder() {
  const { id } = useParams();

  const { token, user } = useSelector((state) => state.auth);
  const [order, setOrder] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [orderStatus, setOrderStatus] = React.useState({});
  const [mycourier, setMyCourier] = React.useState({});
  const [paymentStatus, setPaymentStatus] = React.useState({});
  const [boolPaymentStatus, setBoolPaymentStatus] = React.useState("");
  const [partialPaid, setPartialPaid] = React.useState(0);
  const [partialPaidNote, setPartialPaidNote] = React.useState("");
  const [totalPayable, setTotalPayable] = React.useState(0);
  const [myPaymentStatus, setMyPaymentStatus] = React.useState("");
  const [userLogs, setUserLogs] = React.useState([]);

  const [couriers, setCouriers] = React.useState([]);

  const [options, setOptions] = React.useState([]);

  const TransactionOptions = [
    { value: "nagad", label: "Nagad" },
    { value: "bkash", label: "Bkash" },
  ];

  const [transaction, setTransaction] = React.useState("");
  const [transactionId, setTransactionId] = React.useState("");

  const [specialDiscount, setSpecialDiscount] = React.useState(false);

  const [specialDiscountAmount,setSpecialDiscountAmount]= React.useState(0); 
  const [specialDiscountNote,setSpecialDiscountNote]= React.useState(""); 
  const [specialDiscountNoteCheck,setspecialDiscountNoteCheck]= React.useState(false); 

  useEffect(() => {
    if (partialPaid == 0 || order.partial_paid == 0) {
      setOptions([
        { value: "unpaid", label: "Unpaid" },
        { value: "paid", label: "Paid" },
        { value: "partialpaid", label: "Partial Paid" },
      ]);
    } else {
      setOptions([
        { value: "unpaid", label: "Unpaid" },
        { value: "paid", label: "Paid" },
      ]);
    }
  }, [partialPaid, myPaymentStatus]);

  let history = useHistory();

  React.useEffect(() => {
    if (user?.permissions) {
      if (
        !permission(user.permissions, "order_history", "read") &&
        user.user_type_id != 1
      )
        history.push("/admin");
    }
  }, [user]);

  const fetchOrder = (id) => {
    axios
      .get(`/manage-orders/${id}`, { headers: { Authorization: token } })
      .then((response) => {
        console.log(response);
        setOrder(response.data);
        setStatus(response.data.status);
        axios
          .get("/statuses", { headers: { Authorization: token } })
          .then((response) => {
            // console.log(response.data);
            setStatuses([]);
            response.data.map((item) => {
              setStatuses((prevState) => [
                ...prevState,
                { value: item.name, label: item.name },
              ]);
              // let data = response.data;

              // let index = data.findIndex(order.status);
              // console.log(index);
              setBoolPaymentStatus(response.data.set_paid);
              setPartialPaid(response.data.partial_paid);
            });
          })
          .catch((errors) => {
            console.log(errors.response);
          });
      })
      .catch((errors) => {
        console.log(errors.response);
      });
  };

  const fetchCouriers = () => {
    axios
      .get("/couriers", {
        headers: {
          Accept: "application/json",
          Authorization: token,
        },
      })
      .then((response) => {
        setCouriers([]);
        response.data.map((item) => {
          setCouriers((prevState) => [
            ...prevState,
            { value: item.id, label: item.name },
          ]);
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const getUserLog = (id) => {
    axios
      .get(`manage-orders/logs/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: token,
        },
      })
      .then((response) => {
        // console.log(response);
        setUserLogs(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  React.useEffect(() => {
    if (token != "") {
      fetchOrder(id);
      fetchCouriers();
      getUserLog(id);
    }
  }, [token]);

  React.useEffect(() => {
    setOrderStatus({ value: status, label: status });
  }, [status]);

  React.useEffect(() => {
    if (paymentStatus != null) {
      setPaymentStatus({
        value: boolPaymentStatus,
        label: boolPaymentStatus ? "Paid" : "Unpaid",
      });
    }
  }, [boolPaymentStatus]);

  const updateStatus = (option) => {
    const response = axios
      .post(
        `manage-orders/${id}`,
        {
          _method: "PUT",
          status: option.value,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        // return true;

        getUserLog(id);
      })
      .catch((error) => {
        throw error;
      });

    return response;
  };

  const updateCourier = (option) => {
    const response = axios
      .post(
        `/manage-orders/${id}/courier`,
        {
          courier_id: option.value,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        // return true;

        getUserLog(id);
      })
      .catch((error) => {
        throw error;
      });

    return response;
  };

  const updateTransaction = () => {
    const response = axios
      .post(
        `/manage-orders/${id}/transection`,
        {
          method: transaction,
          transection_id: transactionId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        // return true;
        fetchOrder(id);

        getUserLog(id);
        toast.success("Successfully updated!");
      })
      .catch((error) => {
        throw error;
      });

    return response;
  };

  const handlePartialPrice = (id) => {
    axios
      .post(
        `manage-orders/${id}/partial-paid`,
        {
          partial_paid: partialPaid,
          partial_paid_note: partialPaidNote,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        fetchOrder(id);

        getUserLog(id);
        toast.success("Successfully updated!");
      })
      .catch((err) => {
        console.log(err.response.status);

        if (err.response.status === 404) {
          toast.error(err.response.data.message);
        }
      });
  };

  const handlePaid = (id) => {
    axios
      .post(
        `manage-orders/${id}/update-paid`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        fetchOrder(id);

        getUserLog(id);
        toast.success("Successfully updated!");
      })
      .catch((err) => {
        console.log(err.response.status);

        if (err.response.status === 404) {
          toast.error(err.response.data.message);
        }
      });
  };


  const updateSpecialDiscount = (id)=>{
    console.log("🚓🚗",id,specialDiscountAmount,specialDiscountNote,specialDiscountNoteCheck);

    axios
      .post(
        `manage-orders/${id}/special-discount`,
        {
          special_discount:specialDiscountAmount,
          special_discount_note:specialDiscountNote,
          special_discount_note_checkbox:specialDiscountNoteCheck
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        fetchOrder(id);
        setSpecialDiscount(false);
        getUserLog(id);
        toast.success("Successfully updated!");
      })
      .catch((err) => {
        console.log(err.response.status);

        if (err.response.status === 404) {
          toast.error(err.response.data.message);
        }
      });
  }

  return (
    <>
      <Toast />
      <div className="px-8 mt-8 mb-8 h-screen overflow-y-auto">
        <div className="page-heading">
          <h1 className="pageHeading">
            Order Details of{" "}
            <span className="font-bold uppercase">#{order.order_number}</span>
          </h1>
          <div className="flex"></div>
        </div>
        <div className="flex -mx-1">
          <div className="w-1/2 mx-1">
            <div className="card">
              <div className="border-b">
                <div className="card-header">
                  <div>
                    <h4 className="pageHeading">Shipping Info</h4>
                  </div>
                  {/* <input className="inputBox" placeholder="Search" onChange={e => setSearch(e.target.value)} /> */}
                  {/* <input className="inputBox" placeholder="Search" /> */}
                </div>
              </div>
              <div className="card-body">
                <div className="text-sm text-gray-600">
                  <p className="mb-1">
                    <span className="mr-4 font-bold">Name: </span>
                    {order?.shipping?.name}
                  </p>
                  <p className="mb-1">
                    <span className="mr-4 font-bold">Phone: </span>
                    {order?.shipping?.phone}
                  </p>
                  <p className="mb-4">
                    <span className="mr-4 font-bold">Email: </span>
                    {order?.shipping?.email}
                  </p>
                </div>

                <div className="mt-2 text-sm">
                  <p className="text-gray-600 font-bold">Shipping Address</p>
                  <p>{order?.shipping?.address}</p>
                  <p>
                    {order?.shipping?.area}, {order?.shipping?.city},{" "}
                    {order?.shipping?.region}
                  </p>
                </div>
                <div className="mt-2 text-sm">
                  <p className="text-gray-600 font-bold">Shipping Type</p>
                  <p className="uppercase">{order?.shipping_type} Shipping</p>
                </div>

                <div className="mt-2 text-sm">
                  <p className="text-gray-600 font-bold">
                    {order?.shipping?.sender_name ? "Sender information" : ""}
                  </p>
                  <p>
                    <span className="mr-4 font-bold">Name: </span>
                    {order?.shipping?.sender_name}
                  </p>
                  <p>
                    <span className="mr-4 font-bold">Phone: </span>
                    {order?.shipping?.sender_number}
                  </p>
                </div>
              </div>
            </div>
            {order.transaction ? (
              <div className="card mt-2">
                <div className="border-b">
                  <div className="card-header">
                    <div>
                      <h4 className="pageHeading">Transaction Info</h4>
                    </div>
                    {/* <input className="inputBox" placeholder="Search" onChange={e => setSearch(e.target.value)} /> */}
                    {/* <input className="inputBox" placeholder="Search" /> */}
                  </div>
                </div>
                <div className="card-body">
                  <div className="text-sm text-gray-600">
                    {order.transaction.transaction_id ? (
                      <p className="mb-1">
                        <span className="mr-4 font-bold">Transaction ID: </span>
                        <span className="uppercase">
                          {order?.transaction?.transaction_id}
                        </span>
                      </p>
                    ) : (
                      ""
                    )}
                    <p className="mb-1">
                      <span className="mr-4 font-bold">Payment Method: </span>
                      <span className="uppercase">
                        {order?.transaction?.method}
                      </span>
                    </p>
                    <p className="mb-4">
                      <span className="mr-4 font-bold">
                        Transaction Status:{" "}
                      </span>
                      <span className="uppercase">
                        {order?.transaction?.status}
                      </span>
                    </p>
                    {order?.payment_method === "cod" &&
                    order?.set_paid == 0 &&
                    order?.transaction?.status != "paid" ? (
                      <>
                        <label className="font-bold">
                          Update Payment Status
                        </label>
                        <Select
                         
                          onChange={(option) => {
                            setTransaction(option.value);
                            // toast.promise(updateTransaction(option), {
                            //   loading: "Updating..",
                            //   success: "Updated",
                            //   error: "Something wrong!",
                            // })
                          }}
                          value={transaction}
                          options={TransactionOptions}
                          
                        />


                      </>
                    ) : (
                      ""
                    )}

                    {transaction && order?.transaction?.status != "paid" ? (
                      <>
                        <label className="font-bold">Transaction Id</label>
                        <input
                          type="text"
                          name="transaction_id"
                          className="border-1 block w-80 h-9 focus:outline-none px-4 mt-2 rounded font-DMSans text-sm1 mb-1"
                          onChange={(e) => setTransactionId(e.target.value)}
                        />

                        <button
                          type="submit"
                          onClick={updateTransaction}
                          className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
                        >
                          Update
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="w-1/2 mx-1">
            {user?.permissions &&
              (permission(user.permissions, "order_history", "update") ||
                user.user_type_id == 1) ? (
              <div className="card">
                <div className="border-b">
                  <div className="card-header">
                    <div>
                      <h4 className="pageHeading">Order Status</h4>
                    </div>
                    {/* <input className="inputBox" placeholder="Search" onChange={e => setSearch(e.target.value)} /> */}
                    {/* <input className="inputBox" placeholder="Search" /> */}
                  </div>
                </div>
                <div className="card-body">
                  <div>
                    <label className="text-sm">Select Status</label>
                    {order.status == undefined ? (
                      ""
                    ) : (
                      <Select
                        onChange={(option) => {
                          setOrderStatus(option);
                          toast.promise(updateStatus(option), {
                            loading: "Updating..",
                            success: "Updated",
                            error: "Something wrong!",
                          });
                        }}
                        options={statuses}
                        value={orderStatus}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {user?.permissions &&
              (permission(user.permissions, "order_history", "update") ||
                user.user_type_id == 1) ? (
              <div className="card mt-2">
                <div className="border-b">
                  <div className="card-header">
                    <div>
                      <h4 className="pageHeading">Courier</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div>
                    <label className="text-sm">Select Courier</label>
                    <Select
                      options={couriers}
                      onChange={(option) => {
                        setMyCourier(option);
                        toast.promise(updateCourier(option), {
                          loading: "Updating..",
                          success: "Updated",
                          error: "Something wrong!",
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="card  mt-2">
              <div className="border-b">
                <div className="card-header">
                  <div>
                    <h4 className="pageHeading">Payment Info</h4>
                  </div>
                  {/* <input className="inputBox" placeholder="Search" onChange={e => setSearch(e.target.value)} /> */}
                  {/* <input className="inputBox" placeholder="Search" /> */}
                </div>
              </div>
              <div className="card-body">
                <div className="text-sm text-gray-600">
                  <p>
                    <span className="font-bold mr-2">Payment Method:</span>{" "}
                    {order.payment_method}
                  </p>
                  <p className="mt-2">
                    <span className="font-bold mr-2">Sub Total:</span> TK.{" "}
                    {order.sub_total}
                  </p>
                  <p className="mt-2">
                    <span className="font-bold mr-2">Shipping Total:</span> TK.{" "}
                    {order.shipping_total}
                  </p>
                  <p className="mt-2">
                    <span className="font-bold mr-2">Discount Total:</span> TK.{" "}
                    {order.discount_total}
                  </p>
                  <p className="mt-2">
                    <span className="font-bold mr-2">Total:</span> TK.{" "}
                    {order.total}
                  </p>

                  <p className="mt-2 mb-2">
                    <span className="font-bold mr-2">Payment Status:</span>{" "}
                    {/* {order.set_paid == 1 ? "Paid" : "Unpaid"} */}
                    {order.set_paid == 1
                      ? "Paid"
                      : order.partial_paid == 0
                      ? "Unpaid"
                      : order.partial_paid == order.total
                      ? "Paid"
                      : "Partial Paid"}
                  </p>
                  {order.payment_method == "cod" && order.set_paid == 0 ? (
                    <>
                      <label className="font-bold">Update Payment Status</label>
                      <Select
                        isSearchable={false}
                        onChange={(option) => [
                          console.log(option),
                          //   setPaymentStatus(option),
                          setMyPaymentStatus(option),
                        ]}
                        options={options}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  {myPaymentStatus.value === "partialpaid" &&
                  order.partial_paid == 0 ? (
                    <>
                      <div class="flex">
                        <div class="flex-1 w-64 ...">
                          <label className="font-bold">Amount</label>
                          <input
                            type="text"
                            name="partial_paid"
                            className="border-1 block w-80 h-9 focus:outline-none px-4 mt-2 rounded font-DMSans text-sm1 mb-1"
                            defaultValue={order.partial_paid}
                            onChange={(e) => setPartialPaid(e.target.value)}
                          />
                        </div>
                        <div class="flex-1 w-32 ...">
                          <label className="font-bold">Note</label>
                          <textarea
                            type="text"
                            name="partial_paid_note"
                            className="border-1 block w-full  focus:outline-none px-4 mt-2 rounded font-DMSans text-sm1 mb-1"
                            defaultValue={order.partial_paid_note}
                            onChange={(e) => setPartialPaidNote(e.target.value)}
                          />
                        </div>
                      </div>

                      <button
                        type="submit"
                        onClick={() => handlePartialPrice(order.id)}
                        className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
                      >
                        Update
                      </button>
                    </>
                  ) : (
                    ""
                  )}

                  {order.partial_paid != 0 ||
                  order.partial_paid == order.total ? (
                    <p className="mt-2">
                      <span className="font-bold mr-2">Paid Amount:</span> TK.{" "}
                      {order.set_paid == 1
                        ? order.total
                        : order.partial_paid +
                          "(" +
                          order.partial_paid_note +
                          ")"}
                      {/* {order.partial_paid}- ({}) */}
                    </p>
                  ) : (
                    ""
                  )}

                  {myPaymentStatus.value === "paid" && order.set_paid == 0 ? (
                    <button
                      type="submit"
                      onClick={() => handlePaid(order.id)}
                      className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded "
                    >
                      Update
                    </button>
                  ) : (
                    ""
                  )}

                  {order?.special_discount == 0 &&  order?.transaction?.status != "paid"  ? (
                    <div>
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer mt-5"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        onChange={() => setSpecialDiscount(true)}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800 mt-5"
                        for="flexCheckDefault"
                        checked={specialDiscount}
                      >
                        Special Discount
                      </label>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <p className="mt-2">
                        <span className="font-bold mr-2">
                          Special Discount:
                        </span>{" "}
                        TK. {order.special_discount}
                      </p>
                      {order.special_discount_note?(
                        <p className="mt-2">
                        <span className="font-bold mr-2">
                          Special Discount Note:
                        </span>{" "}
                         {order.special_discount_note}
                      </p>
                      ):""}
                     
                    </>
                  )}

                  {specialDiscount && (
                    <>
                      <div class="flex">
                        <div class="flex-1 w-64 ...">
                          <label className="font-bold">Special Discount</label>
                          <input
                            type="text"
                            name="special_discount"
                            className="border-1 block w-80 h-9 focus:outline-none px-4 mt-2 rounded font-DMSans text-sm1 mb-1"
                            defaultValue={order.special_discount}
                            onChange={(e) =>
                              setSpecialDiscountAmount(e.target.value)
                            }
                          />
                        </div>
                        <div className="flex-1 w-32 ...">
                          <label className="font-bold">Note</label>
                          <textarea
                            type="text"
                            name="special_discount_note"
                            className="border-1 block w-full h-9 focus:outline-none px-4 mt-2 rounded font-DMSans text-sm1 mb-1"
                            defaultValue={order.special_discount_note}
                            onChange={(e) =>
                              setSpecialDiscountNote(e.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div>
                        <input
                          className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                          name="special_discount_note_checkbox"
                          onChange={() => setspecialDiscountNoteCheck(true)}
                        />
                        <label
                          className="form-check-label inline-block text-gray-800"
                          for="flexCheckDefault"
                          checked={specialDiscountNoteCheck}
                        >
                          Show in invoice
                        </label>
                      </div>

                      <button
                        type="submit"
                        onClick={() => updateSpecialDiscount(id)}
                        className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded mt-5"
                      >
                        Update
                      </button>
                    </>
                  )}

                  <p className="mt-2">
                    <span className="font-bold mr-2">Total Payable:</span> TK.{" "}
                    {order.set_paid == 1 ? 0 : (order.total - order.partial_paid)-order.special_discount}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex -mx-1">
          <div className="w-full mx-1 mt-4">
            <div className="card">
              <div className="border-b">
                <div className="card-header">
                  <div>
                    <h4 className="pageHeading">Ordered Item</h4>
                  </div>
                  <div>
                    <Link
                      to={{ pathname: `/admin/order/invoice/print/${id}`, state: "view" }}
                      className="font-Poppins text-xs px-4 py-2 border-1 border-borderColor text-black hover:bg-buttonColor hover:text-black rounded-lg mt-4 inline-block mr-4"
                    >
                      View Invoice
                    </Link>
                    <Link
                      to={{ pathname: `/admin/order/invoice/print/${id}` }}
                      className="font-Poppins text-xs px-4 py-2 border-1 border-borderColor text-black hover:bg-buttonColor hover:text-black rounded-lg mt-4 inline-block"
                    >
                      Print Invoice
                    </Link>
                  </div>
                  {/* <input className="inputBox" placeholder="Search" onChange={e => setSearch(e.target.value)} /> */}
                  {/* <input className="inputBox" placeholder="Search" /> */}
                </div>
              </div>
              <div className="card-body">
                <table className="table-fixed w-full">
                  <thead>
                    <tr className="border-b h-12">
                      <th className="tableHeader w-8">#</th>
                      <th className="tableHeader">Item</th>
                      <th className="tableHeader">Qty</th>
                      <th className="tableHeader">Unit Price</th>
                      <th className="tableHeader">Line Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order?.items?.map((item, index) => (
                      <tr key={index} className="border-b py-4 h-20">
                        <td>
                          <p className="tableData">
                            <span className="font-bold">{index + 1}</span>
                          </p>
                        </td>
                        <td>
                          <p className="tableData">{item.product.name}</p>
                          {item.group != null ? (
                            <>
                              <br />
                              {item.group?.attributes?.map((attr, index) => (
                                <span
                                  className="bg-gray-100 px-2 rounded-full text-sm ml-1"
                                  key={index}
                                >
                                  {attr.value.value}
                                </span>
                              ))}
                            </>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <p className="tableData">{item.quantity}</p>
                        </td>
                        <td>
                          <p className="tableData">TK. {item.unit_price}</p>
                        </td>
                        <td>
                          <p className="tableData">TK. {item.line_total}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="flex -mx-1">
          <div className="w-full mx-1 mt-4">
            <div className="card">
              <div className="border-b">
                <div className="card-header">
                  <div>
                    <h4 className="pageHeading">User Logs</h4>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table-fixed w-full">
                  <thead>
                    <tr className="border-b h-12">
                      <th className="tableHeader">Date-Time</th>
                      <th className="tableHeader">Action</th>
                      <th className="tableHeader">User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userLogs?.map((item, index) => (
                      <tr key={index} className="border-b py-4 h-20">
                        <td>
                          <p className="tableData">
                            <span className="font-bold">
                              <Moment>{item.created_at}</Moment>
                            </span>
                          </p>
                        </td>
                        <td>
                          <p className="tableData">{item.action}</p>
                        </td>
                        <td>
                          <p className="tableData">
                            {item.user.name} <small>({item.user.email})</small>
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
