import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { permission } from 'helper/permission'

const EditingCupon = props => {

    const { token, user } = useSelector(state => state.auth);

    const { state } = useParams()

    let history = useHistory();

    React.useEffect(() => {
        if(user?.permissions)
        {
            if(!(permission(user.permissions, 'offers_coupon', 'update')) && (user.user_type_id != 1))
                history.push('/admin');
        }
    }, [user])

    useEffect(() => {
        console.log(state)
    }, [state])

    const [cuponName, setCuponName] = useState("")
    const [cuponCode, setCuponCode] = useState("")
    const [cuponDescription, setCuponDescription] = useState("")
    const [cuponStartDate, setCuponStartDate] = useState("")
    const [cuponEndDate, setCuponEndDate] = useState("")
    const [maxUse, setMaxUse] = useState("")
    const [cuponValue, setCuponValue] = useState("")
    const [cuponType, setCuponType] = useState(null)
    const [cuponLimit, setCuponLimit] = useState(false)
    const [loading, setLoading] = useState(false)

    const [status, setStatus] = useState(null)

    const cuponTypeOptions = [
        {
            value: "fixed",
            label: "Fixed"
        },
        {
            value: "percentage",
            label: "Percentage"
        }
    ]

    useEffect(() => {
        if (state != undefined) {
            axios.get(`/coupons/${state}`, {
                headers: {
                    Authorization: token,
                    Accept: 'application/json',
                }
            }).then(response => {
                console.log(response)
                setCuponName(response.data?.name)
                setCuponCode(response.data?.code)
                setCuponDescription(response.data?.description)
                setCuponStartDate(response.data?.start_date)
                setCuponEndDate(response.data?.end_date)
                setMaxUse(response.data?.max_use)
                setCuponValue(response.data?.value)
                setCuponType({ value: response.data?.type, label: response.data?.type.charAt(0).toUpperCase() + response.data?.type.slice(1) })
                setCuponLimit(response.data?.is_limited)

            }).catch(errors => {
                console.log(errors.response)
            })
        }
    }, [state])

    useEffect(() => {
        // console.log(cuponLimit)
    }, [cuponLimit])

    const handleAddingCupon = () => {
        const data = {
            name: cuponName,
            code: cuponCode,
            description: cuponDescription,
            start_date: cuponStartDate,
            end_date: cuponEndDate,
            type: cuponType?.value,
            value: cuponValue,
            is_limited: cuponLimit,
            max_use: maxUse
        }
        if (token != "" && state != undefined) {
            const updateData = { ...data, _method: 'PUT' }
            axios.post(`/coupons/${state}`, updateData, {
                headers: {
                    Authorization: token,
                    Accept: 'application/json',
                }
            }).then(response => {
                console.log(response)
                setStatus("Cupon updated successfully")
                setCuponName("")
                setCuponCode("")
                setCuponDescription("")
                setCuponStartDate("")
                setCuponEndDate("")
                setCuponType(null)
                setCuponValue("")
                setCuponLimit(false)
                setMaxUse("")

            }).catch(errors => {
                console.log(errors.response)
                setStatus("Cupon is not updated. Some fields are missing, please fill all of them")
            })
        }
    }

    return (
        <div className="px-8 mt-8 mb-8">

            <div className="w-full mb-6">
                <h1 className="pageHeading">Create Cupon</h1>
                {status && <p>{status}</p>}
            </div>

            <div className="card">

                <div className="w-2/3">

                    <div className="grid grid-cols-12">
                        <div className="col-span-4 flex items-center">
                            <label htmlFor="cuponName" className="createFromInputLabel">Cupon Name *</label>
                        </div>
                        <div className="col-span-8">
                            <input type="text" id="cuponName" className="createFromInputField" placeholder="Enter the name of the Cupon" value={cuponName} onChange={e => setCuponName(e.target.value)} />
                            {/* {errorStatus && <p className="text-red-500 font-Poppins font-medium text-xs">{errorStatus.name}</p>} */}
                        </div>
                    </div>

                    <div className="grid grid-cols-12 mt-4">
                        <div className="col-span-4 flex items-center">
                            <label htmlFor="cuponCode" className="createFromInputLabel">Cupon Code</label>
                        </div>
                        <div className="col-span-8">
                            <input type="text" id="cuponCode" className="createFromInputField" placeholder="Enter the code for the Cupon" value={cuponCode} onChange={e => setCuponCode(e.target.value)} />
                            {/* {errorStatus && <p className="text-red-500 font-Poppins font-medium text-xs">{errorStatus.name}</p>} */}
                        </div>
                    </div>

                    <div className="grid grid-cols-12 mt-4">
                        <div className="col-span-4 flex items-center">
                            <label htmlFor="cuponDescription" className="createFromInputLabel">Cupon Description</label>
                        </div>
                        <div className="col-span-8">
                            <textarea id="cuponDescription" className="createFromInputField" rows="3" placeholder="Enter the description for the Cupon" value={cuponDescription} onChange={e => setCuponDescription(e.target.value)} />
                            {/* {errorStatus && <p className="text-red-500 font-Poppins font-medium text-xs">{errorStatus.short_description}</p>} */}
                        </div>
                    </div>

                    <div className="grid grid-cols-12 mt-4">
                        <div className="col-span-4 flex items-center">
                            <label htmlFor="startDate" className="createFromInputLabel">Cupon Start Date</label>
                        </div>
                        <div className="col-span-8">
                            <input type="date" id="startDate" className="createFromInputField" placeholder="Select Start Date of the Cupon" value={cuponStartDate} onChange={e => setCuponStartDate(e.target.value)} />
                            {/* {errorStatus && <p className="text-red-500 font-Poppins font-medium text-xs">{errorStatus.name}</p>} */}
                        </div>
                    </div>

                    <div className="grid grid-cols-12 mt-4">
                        <div className="col-span-4 flex items-center">
                            <label htmlFor="endDate" className="createFromInputLabel">Cupon End Date</label>
                        </div>
                        <div className="col-span-8">
                            <input type="date" id="endDate" className="createFromInputField" placeholder="Select End Date of the Cupon" value={cuponEndDate} onChange={e => setCuponEndDate(e.target.value)} />
                            {/* {errorStatus && <p className="text-red-500 font-Poppins font-medium text-xs">{errorStatus.name}</p>} */}
                        </div>
                    </div>

                    <div className="grid grid-cols-12 mt-4">
                        <div className="col-span-4 flex items-center">
                            <label htmlFor="cuponType" className="createFromInputLabel">Type of the Cupon: </label>
                        </div>
                        <div className="col-span-8">
                            <Select
                                value={cuponType}
                                onChange={option => { setCuponType(option) }}
                                options={cuponTypeOptions}
                                className="w-full createFromInputLabel selectTag"
                                placeholder="Select Cupon Type"
                                isClearable={true}
                                isSearchable={true}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-12 mt-4">
                        <div className="col-span-4 flex items-center">
                            <label htmlFor="cuponValue" className="createFromInputLabel">Value of the Cupon: </label>
                        </div>
                        <div className="col-span-8">
                            <input type="number" id="cuponValue" className="createFromInputField" placeholder="Enter value of the Cupon" value={cuponValue} onChange={e => setCuponValue(e.target.value)} />
                            {/* {errorStatus && <p className="text-red-500 font-Poppins font-medium text-xs">{errorStatus.name}</p>} */}
                        </div>
                    </div>

                    <div className="flex items-center mt-4">
                        <input type="checkbox" id="limitedOption" checked={cuponLimit} onChange={() => setCuponLimit(prevState => !prevState)} />
                        <label className="font-DMSans text-sm1 ml-2" htmlFor="limitedOption">IS THIS CUPON LIMITED?</label>
                    </div>

                    {
                        cuponLimit && <div className="grid grid-cols-12 mt-4">
                            <div className="col-span-4 flex items-center">
                                <label htmlFor="maxUse" className="createFromInputLabel">Number of Maximum use for the Cupon: </label>
                            </div>
                            <div className="col-span-8">
                                <input type="number" id="maxUse" className="createFromInputField" placeholder="Enter Maximum use for the Cupon" value={maxUse} onChange={e => setMaxUse(e.target.value)} />
                                {/* {errorStatus && <p className="text-red-500 font-Poppins font-medium text-xs">{errorStatus.name}</p>} */}
                            </div>
                        </div>
                    }

                    <div className="mt-8 flex justify-end">
                        {(loading) ? (
                            <>
                                <button className="button button-primary w-32" disabled> <span className="fas fa-sync-alt animate-spin"></span></button>
                            </>
                        ) : (
                            <>
                                <button onClick={handleAddingCupon} className="button button-primary w-32">Update Cupon</button>
                            </>
                        )}

                    </div>

                </div>

            </div>
        </div>
    )
}

export default EditingCupon

