import axios from 'axios';
import { AuthContext } from 'context/AuthContext';
import slugify from 'react-slugify';
import { SellerContext } from 'context/SellerContext';
import { StyleContext } from 'context/StyleContext';
import React, { useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom';
import config from 'config.json'
import { LoaderContext } from 'context/LoaderContext';
import Alert from 'core/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { setSellers } from 'redux/sellers'
import { permission } from 'helper/permission';

export default function EditSeller() {

    const dispatch = useDispatch();

    const [preivew, setPreview] = React.useState('');

    const [name, setName] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [logo, setLogo] = React.useState('');

    const [storeName, setStoreName] = React.useState('');
    const [slug, setSlug] = React.useState('');
    const [description, setDescriptoin] = React.useState('');

    const [errors, setErrors] = React.useState({});

    const [loader, setloader] = React.useState(false);

    const [status, setStatus] = React.useState({});

    const [seller, setSeller] = React.useState({});

    const { sellers } = useSelector(state => state.sellers)

    const [store, setStore] = React.useState({});


    const [checkSlug, setCheckSlug] = React.useState(false);
    const [slugStatus, setSlugStatus] = React.useState(true);

    const { token, user } = useSelector(state => state.auth);

    const { loading, setLoading } = React.useContext(LoaderContext);

    const history = useHistory();

    const { id } = useParams();

    React.useEffect(() => {
        if(user?.permissions)
        {
            // console.log(permission(user.permissions, 'seller_management', 'access'));
            if(!(permission(user.permissions, 'seller_management', 'update')) && (user.user_type_id != 1))
                history.push('/admin');
                // console.log('Not working');
        }
    }, [user])

    const fetchSeller = () => {

        setLoading(true);

        if (token !== '') {
            axios.get(`/sellers/${id}`, {
                headers: {
                    'Authorization': token
                }
            })
                .then(response => {
                    console.log(response)
                    setSeller(response.data);
                    setMobile(response.data.mobile);
                    setName(response.data.name);
                    setEmail(response.data.email);
                    // setPassword(response.data.password);
                    setStore(response.data?.store);
                    setStoreName(response.data.store?.name);
                    setSlug(response.data.store?.slug);
                    setDescriptoin(response.data.store?.description);
                    setPreview(response.data.store?.logo)
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error.response);
                    setLoading(false);
                    if (error.response.status === 404) {
                        history.push('/admin/sellers');
                    }
                })

            // if (sellers.data === undefined) {
            //     if (token !== '') {
            //         axios.get('/sellers', { headers: { Authorization: token } })
            //             .then(response => {
            //                 dispatch(setSellers(response.data))
            //             })
            //     }
            // }
        }
    }

    // React.u 

    React.useEffect(() => {
        fetchSeller();
    }, [token])

    React.useEffect(() => {

    }, [id]);

    React.useEffect(() => {
        setSlug(slugify(slug));
    }, [slug])

    React.useEffect(() => {
        setSlug(slugify(storeName));
    }, [storeName])

    const setImage = (e) => {
        const [file] = e.target.files;

        if (file) {
            setPreview(URL.createObjectURL(file));
            setLogo(file);
        } else {
            setLogo('');
        }
    }

    const removeImage = () => {
        setLogo('');
        setPreview('');
    }

    const getSlugResult = () => {

        if (slug !== '') {
            setCheckSlug(true);

            axios.get(`/sellers/check/${store?.id}/${slug}`, {
                headers: {
                    Authorization: token
                }
            })
                .then(response => {
                    setSlugStatus(response.data);
                    setCheckSlug(false);
                }).catch(error => {
                    console.log(error.response);
                    setCheckSlug(false);
                })
        } else {
            setSlugStatus(false);
        }

    }

    const changeActication = () => {

        setLoading(true);

        axios.post(`/sellers/store/${store.id}/activation`, {
            status: !store.status
        }, {
            headers: {
                Authorization: token
            }
        }).then(response => {
            console.log(store)
            setStore(response.data);
            console.log(response.data)

            // const letSeller = seller;
            // letSeller.store = response.data;

            // // console.log(letSeller);

            // let allSeller = { ...sellers };

            // console.log(allSeller);

            // allSeller = { name: false };

            // const index = allSeller.data.findIndex(item => item.id == id)

            // // console.log(letSeller);
            // // allSeller.data.splice(index, 0, letSeller);
            // // allSeller.data[index] = letSeller;

            // dispatch(setSellers(allSeller));

            setLoading(false);
        })
    }

    const updateSeller = (e) => {
        e.preventDefault();
        setLoading(true);
        setloader(true);
        const data = new FormData();

        data.append('name', name);
        data.append('mobile', mobile);
        data.append('email', email);
        data.append('password', password);

        data.append('logo', logo);
        data.append('store_name', storeName);
        data.append('store_id', store.id);
        data.append('slug', slug);
        data.append('description', description);
        data.append('_method', 'put');

        axios.post(`/sellers/${id}`, data, {
            headers: {
                'Accept': 'application/json',
                'Authorization': token
            }
        }).then(response => {
            console.log(response);
            // let newSeller = response.data;

            // const obj = sellers.data.findIndex(item => item.id == id);

            // const oldSellers = sellers;

            // oldSellers.data[obj] = newSeller;

            // setSellers(oldSellers);

            setStatus({
                message: 'Seller Updated Successfully!',
                type: 'SUCCESS'
            });

            setName('');
            setMobile('');
            setEmail('');
            setPassword('');
            setLogo('');
            setStoreName('');
            setSlug('');
            setDescriptoin('');
            setPreview('');
            // setSlugStatus(false);

            setLoading(false);
            setloader(false);
        }).catch(error => {
            console.log(error);

            if (error.response?.status === 422) {
                setStatus({
                    message: error.response.data.message,
                    type: 'DANGER'
                });
                setErrors(error?.response?.data?.errors)
            }

            setLoading(false);
            setloader(false);
        })
    }

    useEffect(() => {
        console.log(status)
    }, [status])

    // console.log(store.status)

    return (
        <div className="mb-8">
            <div className="px-8 mt-8 mb-4 flex justify-between">
                <p className="font-Poppins font-medium text-base text-pageHeading">Update Seller</p>
                {store ? (
                    <>
                        {
                            store?.status == true ? <button onClick={() => changeActication()} className="button button-danger w-36 font-semibold font-Poppins">DEACTIVE STORE</button> : <button onClick={() => changeActication()} className="button button-success w-36 font-semibold font-Poppins">ACTIVE STORE</button>
                        }
                    </>
                ) : (
                    <></>
                )}
            </div>
            <Alert status={status?.type} type={status?.type} changeStatus={() => setStatus()} message={status?.message} width="w-1/2" margin="ml-8" />
            <div className="grid grid-cols-12 px-8 gap-8">

                <div className="col-span-8">

                    <div className="bg-white w-full shadow-md rounded-md">
                        <p className="py-4 border-b pl-8 font-Poppins font-medium text-base text-pageHeading">Seller Information</p>
                        <div className="pl-8 pr-20 pt-8 pb-8">
                            <div className="grid grid-cols-12">
                                <div className="col-span-4 flex items-center">
                                    <label htmlFor="name" className="createFromInputLabel">Name</label>
                                </div>
                                <div className="col-span-8">
                                    <input type="text" id="name" className="createFromInputField" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                                    <p className="text-red-500 font-Poppins font-medium text-xs">{errors.name}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-12 mt-4">
                                <div className="col-span-4 flex items-center">
                                    <label htmlFor="mobile" className="createFromInputLabel">Mobile Number</label>
                                </div>
                                <div className="col-span-8">
                                    <input type="text" id="mobile" className="createFromInputField" value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder="Mobile Number" />
                                    <p className="text-red-500 font-Poppins font-medium text-xs">{errors.mobile}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-12 mt-4">
                                <div className="col-span-4 flex items-center">
                                    <label htmlFor="email" className="createFromInputLabel">Email</label>
                                </div>
                                <div className="col-span-8">
                                    <input type="email" id="email" className="createFromInputField" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                                    <p className="text-red-500 font-Poppins font-medium text-xs">{errors.email}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-12 mt-4">
                                <div className="col-span-4 flex items-center">
                                    <label htmlFor="password" className="createFromInputLabel">Password</label>
                                </div>
                                <div className="col-span-8">
                                    <input type="password" id="password" className="createFromInputField" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                                    <p className="text-red-500 font-Poppins font-medium text-xs">{errors.password}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white w-full shadow-md rounded-md mt-8">
                        <p className="py-4 border-b pl-8 font-Poppins font-medium text-base text-pageHeading">Seller Store Information</p>
                        <div className="pl-8 pr-20 pt-8 pb-8">
                            <div className="grid grid-cols-12">
                                <div className="col-span-4 flex items-center">
                                    <label htmlFor="store_name" className="createFromInputLabel">Store Name</label>
                                </div>
                                <div className="col-span-8">
                                    <input type="text" id="store_name" onBlur={() => getSlugResult()} className="createFromInputField" value={storeName} onChange={(e) => setStoreName(e.target.value)} placeholder="Store Name" />
                                    <p className="text-red-500 font-Poppins font-medium text-xs">{errors.store_name}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-12 mt-4">
                                <div className="col-span-4 flex items-center">
                                    <label htmlFor="store_description" className="createFromInputLabel">Store Description</label>
                                </div>
                                <div className="col-span-8">
                                    <textarea id="store_description" className="createFromInputField" value={description} onChange={(e) => setDescriptoin(e.target.value)} rows="6" placeholder="Store Description">

                                    </textarea>
                                    <p className="text-red-500 font-Poppins font-medium text-xs">{errors.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-span-4">
                    <div className="bg-white w-full shadow-md rounded-md">
                        <p className="py-4 border-b pl-8 font-Poppins font-medium text-base text-pageHeading">Seller Image</p>
                        {(preivew) ? (
                            <>
                                <div className="relative w-full h-72.25 flex justify-center items-center">
                                    <button onClick={() => removeImage()} className="bg-red-600 text-white p-1 rounded-full absolute right-24 top-0 mr-2 mt-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                    <img className="w-60 h-60" src={preivew} alt="..." />
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="p-8 ">
                                    <label htmlFor="file_upload" className="w-full h-54 flex justify-center items-center border-2 border-dashed">Select Logo</label>
                                    <input id="file_upload" onChange={(e) => setImage(e)} type="file" className="hidden" />
                                </div>
                            </>
                        )}
                        <p className="text-red-500 font-Poppins font-medium text-xs pl-8">{errors.logo}</p>
                    </div>
                </div>
            </div>
            <div className="px-8 mt-8 flex justify-end">
                {(loading) ? (
                    <>
                        <button className="button button-primary w-32" disabled> <span className="fas fa-sync-alt animate-spin"></span></button>
                    </>
                ) : (
                    <>
                        <button onClick={updateSeller} className="button button-primary w-32">Update Seller</button>
                    </>
                )}

            </div>
        </div>
    )
}
