import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

import { LoaderContext } from "context/LoaderContext";
import Pagination from "core/Pagination";
// import toast from "react-hot-toast";
import { permission } from "helper/permission";
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

toast.configure()


const ProductList = () => {
  const [allProducts, setAllProducts] = useState(null);
  const { loading, setLoading } = React.useContext(LoaderContext);
  const [searchValue, setSearchValue] = useState("");
  const { token, user } = useSelector((state) => state.auth);

  const [categories, setCategories] = useState([]);

  const [categoryId, setCategoryId] = useState(null);
  const [url, setUrl] = useState("/products");

  const [errors, setErrors] = React.useState([]);

  let history = useHistory();

  React.useEffect(() => {
    if (user?.permissions) {
      if (
        !permission(user.permissions, "products_manage_product", "access") &&
        user.user_type_id != 1
      )
        history.push("/admin");
    }
  }, [user]);

  const fetchProducts = () => {
    console.log('Product working!!!');
    setLoading(true);
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          Authorization: token,
        },
      })
      .then((response) => {
        setAllProducts(response.data);
        console.log("💥", response);
        setLoading(false);
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  useEffect(() => {
    if (token != null && token != "") {
      console.log(token)
      fetchProducts();

      axios
        .get("/categories", {
          headers: { Authorization: token, Accept: "application/json" },
        })
        .then((response) => {
          setCategories(response.data);
        })
        .catch((errors) => {
          console.log(errors);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token != null && token != "") {
      if (searchValue.length > 3) {
        setUrl("/products/search?terms=" + searchValue);
      }
    }
  }, [searchValue]);

  useEffect(() => {
    if (token != null && token != "") {
      setUrl(`products/filter/${categoryId}`);
    }
  }, [categoryId]);

  const updatePage = (pageurl) => {
    setUrl(pageurl);
  };

  React.useEffect(() => {
    setLoading(true);
    if(token != '')
    {
      axios
      .get(url, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setAllProducts(response.data);
        setLoading(false);
      });
    }
  }, [url]);

  const handleAddingNewDeal = (id) => {
    axios
      .post(
        `/deals`,
        {
          product_id: id,
        },
        {
          headers: {
            Authorization: token,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("successfully Added");
        // toast.success(response);
        fetchProducts();
      })
      .catch((errors) => {
        console.log(errors.response);
        if (errors.response.status === 422) {
          setErrors(errors.response.data.errors);
        }
      });
  };

  const handleAddingBestSeller = (id) => {
    axios
      .post(
        `/bestseller`,
        {
          product_id: id,
        },
        {
          headers: {
            Authorization: token,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("successfully Added");
        fetchProducts();
      })
      .catch((errors) => {
        console.log(errors.response);
        if (errors.response.status === 422) {
          setErrors(errors.response.data.errors);
        }
      });
  };

  useEffect(() => {
    if (token != "") {
     
    }
  }, [token]);

  const addtoHomePage = (id) => {
    axios
      .post(
        `product/${id}/add-homepage`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        fetchProducts();
        toast.success(res);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const removeFromHomePage = (id) => {
    axios
      .post(
        `product/${id}/remove-homepage`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        toast.success(res);
        fetchProducts();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };



  return (
    <>
      <div className="px-8 mt-8 mb-8">
        <div className="page-heading">
          <h1 className="pageHeading">All Products</h1>
          <div className="flex">
            {user?.permissions &&
            (permission(
              user.permissions,
              "products_manage_product",
              "create"
            ) ||
              user.user_type_id == 1) ? (
              <Link
                to="/admin/products-adding"
                className="button button-outline-primary w-48"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2 buttonText">Add a New Product</span>
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="card">
          <div className="border-b">
            <div className="card-header">
              <div>
                <h4 className="pageHeading">Products</h4>
              </div>
              {/* <input className="inputBox" placeholder="Search" onChange={e => setSearchValue(e.target.value)} /> */}
              <label
                htmlFor="Active"
                className="createFromInputLabel w-1/2 "
              ></label>
              <select
                className="createFromInputField"
                onChange={(e) => setCategoryId(e.target.value)}
                //   value={active}
              >
                {/* <option value="1">Active</option>
                  <option value="0">Inactive</option> */}
                <option value="">Select Category</option>
                {categories.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
              <input
                className="inputBox ml-5"
                placeholder="Search"
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>

          <div className="card-body overflow-x-auto">
            <table className="w-350 2xl:w-full table-fixed">
              <thead>
                <tr className="border-b h-12">
                  <th className="tableHeader w-0.7/9">Id</th>
                  <th className="tableHeader w-1.5/9">Name</th>
                  <th className="tableHeader w-1/9">Price</th>
                  <th className="tableHeader w-0.5/9">Status</th>
                  <th className="tableHeader w-0.7/9">Store Name</th>
                  <th className="tableHeader w-0.5/9">Stock</th>
                  <th className="tableHeader w-1/9">Category</th>

                  <th className="tableHeader w-2/9 text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {allProducts?.data?.map((item, index) => (
                  <tr className="border-b py-4 h-20" key={index}>
                    <td>
                      <p className="tableData">{item.id}</p>
                    </td>
                    <td>
                      <div className="flex items-center">
                        {/* {item.thumbnail ? (
                            <>
                              <img
                                className="w-10 h-auto"
                                alt="Product"
                                src={item.thumbnail}
                              />
                            </>
                          ) : (
                            <>
                              <span className="w-10 h-10 bg-gray-200 rounded-full"></span>
                            </>
                          )} */}

                        <div className="ml-4">
                          <h3 className="tableData">
                            {item.name}
                             - (Style Number: {item?.style_no}, SKU: {item.SKU})
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="tableData">
                        <b>Price:</b> {item.price}
                      </p>
                      <p className="tableData">
                        <b>Cost Price:</b> {item.cost_price}
                      </p>
                      <p className="tableData">
                        <b>Special Price:</b> {item.special_price}
                      </p>
                    </td>
                    <td>
                      <p className="tableData uppercase">{item.status}</p>
                    </td>
                    <td>
                      <p className="tableData">{item.store.name}</p>
                    </td>
                    <td>
                      {item.stock ? (
                        <p className="tableData">{item.stock}</p>
                      ) : (
                        <p className="tableData">In Stock</p>
                      )}
                    </td>
                    <td>
                      <p className="tableData">
                        <b>Product Category:</b> {item.product_category.name}
                      </p>
                      <p className="tableData">
                        <b>Sub Category:</b>{" "}
                        {item.product_category.sub_category.name}
                      </p>
                      <p className="tableData">
                        <b>Main Category:</b>{" "}
                        {item?.product_category?.sub_category.category?.name}
                      </p>
                    </td>

                    <td className="text-center">
                      <div className="flex">
                        {user?.permissions &&
                        (permission(
                          user.permissions,
                          "products_manage_product",
                          "update"
                        ) ||
                          user.user_type_id == 1) ? (
                          <Link to={`/admin/product/${item.id}`}>
                            <i className="fas fa-pen mr-2"></i>
                          </Link>
                        ) : (
                          ""
                        )}

                        {user?.permissions &&
                        (permission(
                          user.permissions,
                          "products_deals_of_the_day",
                          "create"
                        ) ||
                          user.user_type_id == 1) ? (
                          <>
                            {item.deal != null ? (
                              <button
                                onClick={() => {
                                  handleAddingNewDeal(item.id);
                                }}
                                className="button bg-green-200 text-white w-32 mr-2"
                                disabled={true}
                                title="Remove Deals from Deal Page"
                              >
                                Added On Deals
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  handleAddingNewDeal(item.id);
                                }}
                                className="button button-success w-32 mr-2"
                              >
                                Deals of the Day
                              </button>
                            )}
                          </>
                        ) : (
                          ""
                        )}

                        {user?.permissions &&
                        (permission(
                          user.permissions,
                          "products_deals_of_the_day",
                          "create"
                        ) ||
                          user.user_type_id == 1) ? (
                          item.inhomepage == false ? (
                            <button
                              onClick={() => {
                                addtoHomePage(item.id);
                              }}
                              className="button button-success w-32 mr-2"
                            >
                              Add to Homepage
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                removeFromHomePage(item.id);
                              }}
                              className="button button-danger w-32 mr-2"
                            >
                              Remove From Homepage
                            </button>
                          )
                        ) : (
                          ""
                        )}

                        {item.bestseller != null ? (
                          <button
                            onClick={() => {
                              handleAddingBestSeller(item.id);
                            }}
                            className="button bg-green-200 text-white w-32 mr-2"
                          >
                            Added On BestSell
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              handleAddingBestSeller(item.id);
                            }}
                            className="button button-success w-32 mr-2"
                          >
                            Best Seller
                          </button>
                        )}
                      </div>
                    </td>

                    {/* <td className="text-center">
                        {user?.permissions &&
                        (permission(
                          user.permissions,
                          "products_deals_of_the_day",
                          "create"
                        ) ||
                          user.user_type_id == 1) ? (
                          <button
                            onClick={() => {
                              handleAddingNewDeal(item.id);
                            }}
                            className="button button-success w-32"
                          >
                            Add to Homepage 
                          </button>
                        ) : (
                          ""
                        )}
                      </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="card-footer">
            <div className="flex flex-col justify-between md:flex-row items-center w-full">
              {allProducts && (
                <p className="font-Poppins font-normal text-sm">
                  Showing{" "}
                  <b>
                    {allProducts.from}-{allProducts.to}
                  </b>{" "}
                  from <b>{allProducts.total}</b> data
                </p>
              )}
              {/* <p>Showing <b>{supplierData.from}-{supplierData.to}</b> from <b>{supplierData.total}</b> data</p> */}

              <div className="flex items-center">
                {allProducts && (
                  <Pagination sellers={allProducts} setUpdate={updatePage} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductList;
